html{
  height: 100%;
}

body{
  overflow: auto;
  background-color: #eeeeee;
  height: 100%;
}
.gridNoMargin{
  margin-right:0 !important;
  margin-left:0 !important;
  width: 100% !important;
}
.gridNoMargin .MuiGrid-item{
  padding: 6px !important;
}
.minWidth300 .MuiDialog-paper{
  min-width: 300px !important;
}
.footer a:visited, .footer a, .footer a:hover{
  color: white;
  text-decoration: none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiAlert-standardError{
  background-color: #d93333 !important;
  color:white !important;
}
.MuiAlert-standardError .MuiAlert-icon{
  color:white !important;
}


.glow3 {
    font-size: 80px;
    color: #fff;
    text-align: center;
    -webkit-animation: glow2 1s ease-in-out infinite alternate;
    -moz-animation: glow2 1s ease-in-out infinite alternate;
    animation: glow2 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow2 {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
}


.neon{
  animation: glow 1s ease-in-out infinite alternate;
}
.anchorText:visited, .anchorText, .anchorText:hover{
    color: black;
    text-decoration: none;
}

@keyframes glow {
  from {
    text-shadow:
            0 0 1px #fff,
            0 0 2px #fff,
            0 0 3px #fff,
            0 0 4px #fff,
            0 0 5px #000000,
            0 0 6px #000000,
            0 0 7px #000000;
  }
  to {
    text-shadow:
            0 0 1px #fff,
            0 0 4px #fff,
            0 0 6px #ff0000,
            0 0 8px #ff0000,
            0 0 10px #ff0000,
            0 0 12px #ff0000,
            0 0 14px #ff0000;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.topping-chips .MuiChip-label {width: 100%}


.background11{
    position: absolute;
    width: 100%;
    min-height:280px;
    background-size: cover;
    background-color:#555555;

}
.paybutton{
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
}
.paybutton:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.paybuttoncancel{
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #797979;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
}
.paybuttoncancel:hover {
    color: #fff;
    cursor: pointer;
    background-color: #9f9f9f;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}